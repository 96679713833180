<template>
    <v-md-editor v-model="text" :height="height" class="v-md-editor-custom"></v-md-editor>
</template>

<script>
import VMdEditor from "@kangc/v-md-editor/lib/codemirror-editor"
import "@kangc/v-md-editor/lib/style/codemirror-editor.css"
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js"
import "@kangc/v-md-editor/lib/theme/style/vuepress.css"
import enUS from "@kangc/v-md-editor/lib/lang/en-US"

// Resources for the codemirror editor
import Codemirror from "codemirror"
// mode
import "codemirror/mode/markdown/markdown"
// placeholder
import "codemirror/addon/display/placeholder"
// active-line
import "codemirror/addon/selection/active-line"
// scrollbar
import "codemirror/addon/scroll/simplescrollbars"
import "codemirror/addon/scroll/simplescrollbars.css"
// style
import "codemirror/lib/codemirror.css"

VMdEditor.Codemirror = Codemirror
VMdEditor.use(vuepressTheme)
VMdEditor.lang.use("en-US", enUS)

export default {
    name: "MarkdownEditor",

    props: {
        height: {
            type: String,
            default: "400px"
        },

        value: {
            type: String
        }
    },

    components: {
        "v-md-editor": VMdEditor
    },

    data() {
        return {
            text: this.value || ""
        }
    },

    watch: {
        text(newVal) {
            this.$emit("input", newVal)
        },

        value(newValue) {
            this.text = newValue
        }
    }
}
</script>

<style lang="scss">
.v-md-editor-custom {
    background-color: var(--pl-markdown-editor-bg);
}

.vuepress-markdown-body {
    background-color: var(--pl-markdown-editor-bg);
    color: var(--pl-markdown-editor-text-color);
}

// .v-md-editor__toolbar-item {
//   color: var(--pl-markdown-editor-toolbar-icon-color);
// }
</style>
